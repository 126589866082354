/* eslint-disable no-underscore-dangle */
import styled, { css } from "styled-components"
import { Cta } from "../LUButton/style"

export const NavigationBar = styled.nav`
  z-index: 111;

  position: fixed;

  display: flex;
  justify-content: center;

  width: 100%;
  min-height: ${props => (props.isViewportAtTop ? "70px" : props.theme.components.navigationBar.scrollingHeight.pixels)};

  background: ${({ isViewportAtTop, startBgColour }) => (isViewportAtTop ? startBgColour : "#fff")};

  ${props =>
    !props.isViewportAtTop &&
    css`
      box-shadow: rgba(0, 0, 0, 0.31) 0px 10px 30px 0px;
    `}

  transition: min-height 0.1s ease-in-out, background-color 0.1s ease-in-out;

  ${props =>
    props.isNewMobileTemplate
      ? css`
          height: 56px;
          min-height: 56px;
          background: ${props.isOrangeBgLayout ? "#ff6600" : "radial-gradient(100% 104px at 0 calc(100% + 56px), #f18825, #fd5c3c)"};
        `
      : css`
          @media (min-width: 768px) {
            min-height: ${props.isViewportAtTop
              ? props.theme.components.navigationBar.atTopHeight.pixels
              : props.theme.components.navigationBar.scrollingHeight.pixels};
          }
        `}
`

export const NavBarInner = styled.div`
  max-width: 1280px;
  padding: 0 ${props => props.theme.boxLayout.margin.value};

  display: flex;
  align-items: center;
  justify-content: ${props => (props.isLogoCentered && props.isViewportAtTop ? "center" : "space-between")};
  flex-wrap: wrap;

  width: 100%;

  ${Cta} {
    margin: 0 0 0 auto;
    background: none;
    color: ${({ theme }) => theme.colours.primary};
    border: 1px solid ${({ theme }) => theme.colours.primary};
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

    :hover {
      background: ${({ theme }) => theme.colours.primary};
      color: #fff;
    }
    ${props =>
      props.isNewMobileTemplate &&
      css`
        border: none;
        background: #1daa63;
        color: #fff;
      `}
  }
`

export const FlexContainer = styled.div`
  display: inline-flex;
  align-items: center;
`
export const Items = styled.ul`
  display: flex;
  justify-content: flex-end;

  padding: 0;
`

export const Item = styled.li`
  list-style-type: none;

  a {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: ${props => props.textColour};

    :hover {
      color: ${props => props.textHoverColour};
    }
  }

  :not(:last-child) {
    margin-right: 30px;
  }
`

// export const IGOLogo = styled.img`
//   width: 95px;
//   @media (min-width: 769px) {
//     width: 150px;
//   }
// `

export const PartOfMgmLogo = styled.img`
  width: 200px;

  @media (max-width: 511px) {
    display: none;
  }
`

export const PartOfMgmLogoMobile = styled.img`
  width: 60px;

  @media (min-width: 272px) {
    width: 95px;
  }

  @media (min-width: 367px) {
    width: 135px;
  }

  @media (min-width: 512px) {
    display: none;
  }
`
